export const JackpotBannerTranslations = {
  en: {
    jackpotBanners: {
      loadingText: `Loading, please wait...`,
    }
  },
  zh: {
    jackpotBanners: {
      loadingText: `加载请稍候 ...`,
    }
  },
  fr: {
    jackpotBanners: {
      loadingText: `Chargement, veuillez patienter...`,
    }
  },
  ro: {
    jackpotBanners: {
      loadingText: `Se incarca, va rugam asteptati...`,
    }
  },
  es: {
    jackpotBanners: {
      loadingText: `Cargando por favor espere...`,
    }
  },
  pt: {
    jackpotBanners: {
      loadingText: `Carregando, por favor espere...`,
    }
  },
  de: {
    jackpotBanners: {
      loadingText: "Laden, bitte warten...",
    }
  }
};
