<svelte:options tag={null} />
<script lang="ts">

import { isMobile } from 'rvhelper';
import { _, addNewMessages, setLocale } from './i18n';
import { JackpotBannerTranslations } from './translations';

// Type decides the visual structure of the widget, as per the requirements
export let visualstructure:string = '';
export let title:string = '';
// Exposing these attributes individually as the links could get pretty long and difficult to discern for a human parsing the HTML.
export let caticon0:string = '';
export let caticon1:string = '';
export let caticon2:string = '';
export let caticon3:string = '';
// Exposing the labels as a single string since the input is short and concise
export let iconlabels:string = '';
export let backgroundsrc:string='';
export let currency:string='';
// If mobile background is not given, the desktop one is used instead
export let backgroundsrcmobile:string='';
export let titlelogopath = '';
export let lang:string ='en';
export let clientstyling:string = '';
export let clientstylingurl:string = '';

export let endpoint:string = '';
export let gameid:string = ''; // which game's data to pull


let userAgent:any = window.navigator.userAgent;
let mobileView:boolean = false;
let tabletView:boolean = false;

let vendorIcon:string = ``;

let jackpotValues:Array<number> = [];
let validGameIds:Array<string> = [];
let formattedJackpotValues:Array<string> = [];
let formattedLabels:Array<string> = [];
let caticons:Array<string> =[];
let backgroundImg:string = "";
let titleLogoImg:string = "";
let customStylingContainer: HTMLElement;
let displayNone:boolean = false;

let isLoading:boolean = true;
let hasErrors:boolean = false;
let receivedJackpotData:any;

 Object.keys(JackpotBannerTranslations).forEach((item:any) => {
    addNewMessages(item, JackpotBannerTranslations[item]);
  });

const availableTypes = ['typeSpread', 'typeLeft'];

const setJackpotValues = (jackpotData:any):void => {
  const valuesObj = jackpotData?.items[0]?.additionalData;
  
  if(valuesObj) Object.values(valuesObj).forEach(value => jackpotValues.push(+value));
}

const setVendorLogo = (jackpotLogoData):void => {
  vendorIcon = jackpotLogoData.items[0].vendorLogo;
}

const getJackpotData = () => {
  if(endpoint && lang && currency) {
    let url:URL = new URL(`${endpoint}/casino/jackpots`);

    url.searchParams.append('filter', `id=${gameid}`);
    url.searchParams.append('language', lang);
    url.searchParams.append('additionalDataCurrency', currency);

    fetch(url.href)
    .then((res:any) => 
      res.json().then((data:any) => {
        isLoading = false;
        
        receivedJackpotData = data;
        
        if(validGameIds.includes(gameid)) {
          setJackpotValues(receivedJackpotData);
          setVendorLogo(receivedJackpotData);
        }
      }).catch((err:any) => {
      hasErrors = true;
      isLoading = false;

      console.error(err);
      })
    )
  }
}

const getJackpotConfig = () => {
  let url:URL = new URL(`${endpoint}/casino/jackpots/config`);

  fetch(url.href)
    .then((res:any) => res.json())
    .then((configData:any) =>{
      validGameIds = configData.egtJackpotIds.map(id => id);
    })
}

const initialize = ():void => {
  // Also checking that ratio is above 1 to work with edge cases where the ratio is landscape but the userAgent comes back as mobile
  mobileView = isMobile(userAgent) && window.innerHeight / window.innerWidth > 1;
  tabletView = isTablet();

  // Format jackpot values & labels
  if(jackpotValues.length !== 0) {
    formattedJackpotValues = formatJackpotValues(jackpotValues);
  }

  formattedLabels = iconlabels ? iconlabels.split(',').map(label => label.trim().toUpperCase()) : new Array(4).fill('');

  // check if visualtype passed is correct (longer string prone to errors. maybe shorten the strings to be less descriptive, but also less error prone?)
  if (availableTypes.includes(visualstructure.trim())) {
    visualstructure = visualstructure.trim();
  } else {
    console.warn(`Invalid Attribute Value: "${visualstructure}" is not a valid value for the "visualstructure" attribute. Defaulting to "${availableTypes[0]}"`);
    visualstructure = availableTypes[0];
  }

  backgroundImg = backgroundsrcmobile && mobileView && !tabletView ? backgroundsrcmobile : backgroundsrc;
  titleLogoImg = titlelogopath;
  caticons = [caticon0, caticon1, caticon2, caticon3]; // This isn't pretty, we could move to caticons as a list, but it'll look bad in the DOM since they can hold long URLs
}

const formatJackpotValues = (jackpotValues:Array<number>):Array<string> => {
  return jackpotValues.map(val => Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(val));
};


const isTablet = ():boolean => {
  const ratio = window.innerHeight / window.innerWidth;
  return 0.75 < ratio && ratio < 1.6;
};

const handleClick = ():void => {
  window.postMessage({ type: 'JackpotBannerClicked', gameid }, window.location.href);
}

const setClientStyling = ():void => {
    let sheet = document.createElement('style');
    sheet.innerHTML = clientstyling;
    customStylingContainer.appendChild(sheet);
  }

  const setClientStylingURL = ():void => {
    displayNone = true;

    let url:URL = new URL(clientstylingurl);
    let cssFile:HTMLElement = document.createElement('style');

    fetch(url.href)
      .then((res:any) => res.text())
      .then((data:any) => {
        cssFile.innerHTML = data

        if (customStylingContainer) {
          setTimeout(() => { customStylingContainer.appendChild(cssFile) }, 1);
          setTimeout(() => { displayNone = false; }, 500);
        }
      });
}

const setActiveLanguage = () => {
    setLocale(lang);
  }

$: endpoint && getJackpotConfig();
$: customStylingContainer && clientstyling && setClientStyling();
$: customStylingContainer && clientstylingurl && setClientStylingURL();
$: (endpoint && lang && currency) && validGameIds.length > 0 && getJackpotData();
$: currency && lang && !isLoading && initialize();
$: lang && setActiveLanguage();
</script>

{#if hasErrors}
  <p>500 Server error</p>
{:else if isLoading}
  <p class="loadingPrompt">{$_('jackpotBanners.loadingText')}</p>
{:else}
  <div bind:this={customStylingContainer} class={displayNone ? 'DisplayNone' : ''} part="{displayNone ? 'DisplayNone' : ''}">
    <div class="jackpotBanner" on:click={() => handleClick()}>
      <div class="mainWrapper {visualstructure} {mobileView && !tabletView ? 'mobile' : tabletView ? 'tablet' : ''}" style="background: url({backgroundImg});" >
        <div class="vendorLogo"><img src="{vendorIcon}" alt=""></div>
        <div class="displayContainer">
          {#if !tabletView}
            {#each Array(4) as _, i}
            <div class="displayCard"><div class="iconWrapper"><img class="displayCardIcon" src="{caticons[i]}" alt=""><span class="cardLabel">{formattedLabels[i]}</span></div><span class="cardTextWrapper"><span class="jackpotValue">{formattedJackpotValues[i] ?? ''}</span> <span class="jackpot-currency">{currency}</span></span></div>
            {/each}
          {:else}
          <!-- Building these by looping would be a bit unreadable and require a higher scope counting variable for correct array access. I can do that, but this approach, while not DRY, is easier to parse -->
          <div class="pairWrapper">
            <div class="displayCard"><div class="iconWrapper"><img class="displayCardIcon" src="{caticons[0]}" alt=""><span class="cardLabel">{formattedLabels[0]}</span></div><span class="cardTextWrapper"><span class="jackpotValue">{formattedJackpotValues[0]}</span> <span class="jackpot-currency">{currency}</span></span></div>
            <div class="displayCard"><div class="iconWrapper"><img class="displayCardIcon" src="{caticons[1]}" alt=""><span class="cardLabel">{formattedLabels[1]}</span></div><span class="cardTextWrapper"><span class="jackpotValue">{formattedJackpotValues[1]}</span> <span class="jackpot-currency">{currency}</span></span></div>
          </div>
          <div class="pairWrapper">
            <div class="displayCard"><div class="iconWrapper"><img class="displayCardIcon" src="{caticons[2]}" alt=""><span class="cardLabel">{formattedLabels[2]}</span></div><span class="cardTextWrapper"><span class="jackpotValue">{formattedJackpotValues[2]}</span> <span class="jackpot-currency">{currency}</span></span></div>
            <div class="displayCard"><div class="iconWrapper"><img class="displayCardIcon" src="{caticons[3]}" alt=""><span class="cardLabel">{formattedLabels[3]}</span></div><span class="cardTextWrapper"><span class="jackpotValue">{formattedJackpotValues[3]}</span> <span class="jackpot-currency">{currency}</span></span></div>
          </div>
          {/if}
        </div>
        <div class="title">
          <img src={titleLogoImg} alt=""/>
        </div>
      </div>
    </div>
  </div>
{/if}

<style lang="scss">
:host {
  font-family: "Roboto", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji";
  color: #fff;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
}

.mainWrapper {
  padding: 33px 3vw;
  // For some reason the browser overrides these values unless !important, despite them not being set anywhere else in this document
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: 7px;

  display: grid;
  grid-gap: 0.5rem;

  z-index: 0;
}

.vendorLogo {
  grid-area: vendorLogo;
  height: 24px;
  width: auto;

  display: flex;
  align-items: center;

  img {
    height: 100%;
    width: auto;
  }
}

.displayCard {
  position: relative;
  background: #1c2136;
  height: Max(0.5vw, 1rem);
  padding: 10px 20px 10px 24px;
  margin: 0 8px;

  box-sizing: content-box;
  border-radius: 7px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #ffc052;

  .iconWrapper {
    width: 44px;
    height: 44px;

    position: absolute;
    left: -24px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .displayCardIcon {
    height: 100%;
    width: auto;
  }

  .cardLabel {
    font-size: 0.8rem;
    font-style: italic;
  }

  .cardTextWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: flex-end;
  }
}

.title {
  grid-area: title;
  display: flex;
  align-items: center;
  img {
    height: 50px;
  }
}

.displayContainer {
  grid-area: displayArea;
  padding: 0 0 0 20px;

  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;

  .jackpotValue {
    font-size: 1.2rem;
    font-weight: 400;
    display: flex;
    align-items: flex-end;
  }

  .jackpot-currency {
    font-weight: 400;
    font-style: italic;
    font-size: 8px;

    padding-left: 2px;
    display: flex;
    align-items: flex-end;
  }
}

.pairWrapper {
  width: 100%;
  padding-left: 4%;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}

.typeSpread {
  grid-template-columns: 1fr 2.5fr 1fr;
  grid-template-areas: "title displayArea vendorLogo";
  align-items: center;

  &.mobile {
    grid-template-areas:
      "title"
      "vendorLogo"
      "displayArea";
    margin-top: 25px;
  }

  .pairWrapper {
    justify-content: center;
  }

  .vendorLogo {
    justify-content: flex-end;
  }

  .title {
    justify-content: flex-start;
  }

  .displayContainer {
    justify-content: space-evenly;
  }
}

.typeLeft {
  grid-template-columns: 1fr 4fr;
  grid-template-areas:
    "title displayArea"
    "vendorLogo displayArea";
  padding: 44px 33px;

  &.mobile {
    grid-template-areas:
      "title"
      "vendorLogo"
      "displayArea";
    padding: 33px;

    .displayContainer {
      margin-top: 50px;
      gap:2.6rem;
    }
  }

  .displayContainer {
    padding-left: 2rem;
    width: clamp(30vw, auto, 50vw);
    gap: 2rem;
  }

  .pairWrapper {
    justify-content: space-evenly;
  }

  .title {
    align-items: flex-end;
  }

  .vendorLogo {
    height: 24px;
    width: auto;
  }
}

.mobile {
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  .title {
    justify-content: center;
    align-items: center;
    img {
      height: 80px;
    }
  }

  .displayContainer {
    // this padding coutners the left movement of the absolutely positioned card icons on mobile, in order to make them look centered, despite being offset
    padding-left: 26px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .vendorLogo {
    justify-content: center;
    align-items: center;
  }

  .displayCard {
    width: 40vw;
  }
}

.tablet {
  .displayContainer {
    flex-wrap: wrap;
  }
}

.DisplayNone {
    display: none;
  }

.loadingPrompt {
  color: #333;
}
</style>
